<!-- 运营添加示例图-->
<template>
  <div>
    <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      custom-class="ele-dialog-form"
      :title="isUpdate ? '编辑示例图' : '添加示例图'"
      @update:visible="updateVisible"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="130px">
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label="示例图名称:" prop="sample_graph_name">
              <el-input
                placeholder="请输入示例图名称"
                v-model.trim="form.sample_graph_name"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="拍照要求:" prop="claim">
              <el-input
                placeholder="请输入拍照要求"
                v-model.trim="form.claim"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="示例图编码:" prop="coding">
              <el-input
                placeholder="请输入示例图编码"
                v-model.trim="form.coding"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="拍照图例" prop="sample_graph_path">
              <div class="upload_bg_shouchi">
                <el-upload
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload"
                  :limit="1"
                  accept=".jpg, .png, jpeg"
                  :file-list="fileList"
                  :class="{ hide_box: handupload_btn }"
                  :on-change="handchange"
                  list-type="picture-card"
                  :on-preview="handPreview"
                  :on-remove="handhandleRemove"
                >
                  <!--<i class="el-icon-plus"></i>-->
                  <img
                    style="margin-top: 20px"
                    src="@/assets/images/home/upload_anniu.png"
                    alt=""
                  />
                  <div
                    style="
                      font-size: 12px;
                      font-weight: 500;
                      color: #ff9b05;
                      margin-top: 10px;
                    "
                  >
                    上传示例图
                  </div>
                  <div
                    style="
                      font-size: 12px;
                      font-weight: 400;
                      color: #c1c2ce;
                      margin-top: 20px;
                    "
                  >
                    支持jpg，jpeg，png格式，最大10M
                  </div>
                </el-upload>
                <el-dialog
                  :visible.sync="handdialogVisible"
                  :before-close="handleClose"
                  :modal="false"
                >
                  <img width="100%" :src="handdialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </el-form-item>
            <el-form-item label="示例图属性:" prop="items_attribute">
              <el-radio-group v-model="form.items_attribute">
                <el-radio label="all">所有</el-radio>
                <el-radio label="single">单地址</el-radio>
                <el-radio label="double">双地址</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="状态:" prop="status">
              <el-radio-group v-model.number="form.status">
                <el-radio :label="0">启用</el-radio>
                <el-radio :label="1">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="归属系统:" prop="is_system">
              <el-radio-group v-model.number="form.is_system">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="updateVisible(false)">取消 </el-button>
        <el-button
          style="margin-left: 20px"
          type="primary"
          :loading="loading"
          @click="save('form')"
          >确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 获取配置
import setting from "@/config/setting";
import { getCookies } from "@/utils/cookies";
import Config from "@/config";
import { Addsamples, Editsamples, Detailssample_graph } from "@/api/operate";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
  },
  components: {},
  data() {
    let checkwhitename = (a, value) => {
      // console.log(value);
      if (value != null) {
        callback(new Error("示例图路径长度必须在1到25个字符之间"));
      } else {
        callback();
      }
    };
    return {
      // 表单数据  合并对象
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        sample_graph_name: [
          { required: true, message: "请输入名称", trigger: "change" },
          {
            min: 0,
            max: 50,
            message: "长度最多输入50个字符",
            trigger: "change",
          },
        ],
        claim: [
          { required: true, message: "请输入拍照要求", trigger: "change" },
          {
            min: 0,
            max: 80,
            message: "长度最多输入80个字符",
            trigger: "change",
          },
        ],
        coding: [
          { required: true, message: "请输入示例图编码" },
          {
            min: 0,
            max: 32,
            message: "长度最多输入32个字符",
            trigger: "change",
          },
        ],
        items_attribute: [
          { required: true, message: "请选择示例图属性", trigger: "change" },
        ],
        status: [
          {
            required: true,
            type: "number",
            message: "请选择状态",
            trigger: "change",
          },
        ],
        sample_graph_path: [
          {
            required: false,
            message: "请上传示例图",
          },
          // {min:0,max:1000,message:"图片路径太长了",trigger:'change'}
        ],
        is_system: [
          {
            required: true,
            type: "number",
            message: "请选择归属系统",
            trigger: "change",
          },
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传接口
      action: setting.apiBaseURL + "common/upload/sample_graph",
      // 上传请求头部
      headers: {
        "X-Token": "Bearer" + " " + getCookies(Config.tokenStoreName),
      },
      //营业执照照片
      handdialogImageUrl: "",
      handdialogVisible: false,
      handupload_btn: false,
      fileList: [],
    };
  },
  watch: {
    data() {
      // if (this.data) {
      //   console.log(this.data)
      //   this.form = Object.assign({}, this.data);
      //   this.isUpdate = true;
      //   this.fileList = [];
      //   let where = {
      //     url: this.data.sample_graph_path
      //   }
      //   this.handupload_btn = true;
      //   this.fileList.push(where);
      // } else {
      //   this.form = {};
      //   this.handdialogImageUrl = '';
      //   this.handdialogVisible = false;
      //   this.handupload_btn = false;
      //   this.fileList = [];
      //   this.isUpdate = false;
      // }
    },
  },
  mounted() {
    //如果父组件传过来的数据有值,则为修改
    if (this.data) {
      // console.log(this.data);
      // 获取详情
      Detailssample_graph(this.data.id).then((res) => {
        //根据父组件传过来的id发起请求拿到此接口返回来的数据结果
        console.log(res);
        if (res.code == 200) {
          this.form = res.data; //把返回来的数据赋值给form然后展示到页面
          this.isUpdate = true; //把修改的状态赋值为true
          this.fileList = []; //声明一个空数组
          let where = {
            //声明一个变量来接受图片的路径
            url: res.data.sample_graph_path,
          };
          this.handupload_btn = true; //把上传按钮的显示状态赋值为true
          this.fileList.push(where); //把图片路径放到fileList数组中
        } else {
          console.log("失败了"); //如果失败了就提示错误信息
        }
      });
    } else {
      this.form = {}; //如果不是修改,则把form赋值为空
      this.handdialogImageUrl = ""; //把图片的地址赋值为空
      this.handdialogVisible = false; //把弹窗的显示状态赋值为false
      this.handupload_btn = false; //把上传按钮的显示状态赋值为false
      this.fileList = []; //把fileList赋值为空
      this.isUpdate = false; //把修改的状态赋值为false
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      //表单提交的方法
      console.log(this.form);
      // console.log(this.form.sample_graph_path);
      console.log(this.rules.sample_graph_name[0].required);
      this.$refs["form"].validate((valid) => {
        // console.log(this.form);
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if (this.isUpdate === false) {
            // console.log("新增的数据");
            // console.log(this.form);
            // 把form里面的数据通过Addsamples方法传给后台
            Addsamples(this.form)
              .then((res) => {
                // console.log(res);
                this.loading = false;
                //判断后台返回的状态码如果是200就代表成功
                if (res.code === 200) {
                  this.$message.success(res.msg);
                  if (!this.isUpdate) {
                    //清除表单里面的数据
                    this.form = {}; //把form赋值为空
                  }
                  //子组件来更新父组件里的弹窗状态
                  this.updateVisible(false);
                  //子组件提醒父组件调用done事件 然后调用父组件的获取列表方法刷新页面
                  this.$emit("done");
                } else {
                  //更新失败后提示
                  this.$message.error(res.msg);
                }
              })
              .catch((e) => {
                //提交状态改为false
                this.loading = false;
                //通过catch方法来捕获错误信息
                this.$message.error(e.msg);
              });
          } else {
            let data = this.form;
            // console.log("修改的数据");
            console.log(this.form);
            //通过Editsamples方法把更新好的数据传给后台
            Editsamples(data.id, data)
              .then((res) => {
                // console.log(res);
                //提交状态改为false
                this.loading = false;
                //判断后台返回的状态码如果是200就代表成功
                if (res.code === 200) {
                  //更新成功后提示
                  this.$message.success(res.msg);
                  if (!this.isUpdate) {
                    //更新成功后把表单里面的数据清空
                    this.form = {};
                  }
                  //子组件来更新父组件里的弹窗状态
                  this.updateVisible(false);
                  //子组件通知父组件调用done事件 然后调用父组件的获取列表方法刷新页面
                  this.$emit("done");
                } else {
                  //更新失败后提示
                  this.$message.error(res.msg);
                }
              })
              .catch((e) => {
                //提交状态改为false
                this.loading = false;
                //通过catch方法来捕获错误信息
                this.$message.error(e.msg);
              });
          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      //更新父组件的弹窗状态
      this.$emit("update:visible", value);
    },

    //营业执照照片事件
    handchange() {
      this.handupload_btn = true;
    },
    handhandleRemove() {
      this.fileList = [];
      this.handupload_btn = false;
      // 手动验证路径是否存在
      this.handdialogImageUrl = "";
      this.form.sample_graph_path = null;
      // this.rules.sample_graph_name[0].required = true;
      // this.$refs.form.validate("sample_graph_path").then((res)=>{
      //   console.log("验证成功")
      // }).catch(()=>{
      //   console.log("验证失败")
      // })
    },
    handPreview(file) {
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },
    handleClose() {
      this.handdialogVisible = false;
    },
    //上传成功
    successUpload(file, fileList) {
      // console.log("上传成功");
      // console.log(file);
      // console.log(fileList);
      console.log(file);
      //后端处理后返回的图片路径
      this.handdialogImageUrl = file.data.urls;
      // 上传成功后的列表
      console.log(this.form.sample_graph_path);
      this.form.sample_graph_path = file.data.urls;
      //手动验证上传是否成功
      // console.log('打印this.form')
      // console.log(this.form)
      //  this.ruleForm.fileList.push(file)
      //清除单项路径验证
      // this.rules.sample_graph_name[0] = { required: false, message: "请上传图片", trigger: "blur" };
      // this.$refs.form.clearValidate("sample_graph_path");
      // this.rules.sample_graph_name[0].required = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//样式穿透
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
